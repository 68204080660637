import React, { Component, Fragment } from "react";

import Menu from "../components/Menu";
import Footer from "../components/Footer";
import global from "../global";
class Services extends Component {
  render() {
    return (
      <Fragment>
        <Menu />
        <section className="page-section" id="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2 className="section-heading text-uppercase">Servicios</h2>
                <h3 className="section-subheading mb-5 text-muted">
                  {global.leyendaServicios}
                </h3>
              </div>
            </div>
            <div className="row text-center">
              <div className="col-md-4">
                <span className="fa-stack fa-4x">
                  <i className="fas fa-circle fa-stack-2x text-primary"></i>
                  <i className="fas fa-shopping-cart fa-stack-1x fa-inverse"></i>
                </span>
                <h4 className="service-heading">e-Commerce</h4>
                <p>
                <div className="list-group">
                  {/*<a
                    href="https://mayoreo.govinet.app"
                    target="_new"
                    className="list-group-item list-group-item-action d-flex justify-content-between align-items-center text-muted py-1"
                  >
                    Clientes Mayoreo
                    <span className="badge badge-success badge-pill">
                      Nuevo
                    </span>
                  </a>*/}

                  <a
                    href="https://sucursales.govinet.app"
                    target="_new"
                    className="list-group-item list-group-item-action d-flex justify-content-between align-items-center text-muted py-1"
                  >
                    Clientes Sucursales
                  </a>
                  <a
                    href="https://facturacion.govinet.app"
                    target="_new"
                    className="list-group-item list-group-item-action d-flex justify-content-between align-items-center text-muted py-1"
                  >
                    Facturaci&oacute;n Electr&oacute;nica
                    <span className="badge badge-success badge-pill">
                      Nuevo
                    </span>
                  </a>
                  {/*<a
                    href="https://tienda.govinet.app"
                    target="_new"
                    className="list-group-item list-group-item-action d-flex justify-content-between align-items-center text-muted py-1"
                  >
                    P&uacute;blico en General
                    <span className="badge badge-success badge-pill">
                      Nuevo
                    </span>
                  </a>
                  */}
                </div>
                </p>
              </div>
              <div className="col-md-4">
                <span className="fa-stack fa-4x">
                  <i className="fas fa-circle fa-stack-2x text-primary"></i>
                  <i className="fas fa-check fa-stack-1x fa-inverse"></i>
                </span>
                <h4 className="service-heading">Calidad</h4>
                <p className="text-muted">
                  Nuestros proveedores son fabricantes de equipo original,
                  cuentan con los m&aacute;s altos est&aacute;ndares de calidad
                  y son avalados con diferentes certificados de procesos y medio
                  ambiente.
                </p>
              </div>
              <div className="col-md-4">
                <span className="fa-stack fa-4x">
                  <i className="fas fa-circle fa-stack-2x text-primary"></i>
                  <i className="fas fa-shield-alt fa-stack-1x fa-inverse"></i>
                </span>
                <h4 className="service-heading">Garant&iacute;a</h4>
                <p className="text-muted">
                  La calidad de nuestros productos <b>GROB</b> est&aacute; garantizada
                  contra defectos de fabricaci&oacute;n por 1 año en partes de
                  suspensi&oacute;n, direcci&oacute;n y tracci&oacute;n
                  delantera, por 4 años o 100,000 km en amortiguadores.
                </p>
              </div>
            </div>
            <div className="row text-center">
              <div className="col-md-4">
                <span className="fa-stack fa-4x">
                  <i className="fas fa-circle fa-stack-2x text-primary"></i>
                  <i className="fas fa-clock fa-stack-1x fa-inverse"></i>
                </span>
                <h4 className="service-heading">Entrega</h4>
                <p className="text-muted">
                  Entregamos a domicilio en menos de 1 hora en mostrador, 4
                  horas en mayoreo local y de 24 a 48 horas a for&aacute;neos.
                </p>
              </div>

              <div className="col-md-4">
                <span className="fa-stack fa-4x">
                  <i className="fas fa-circle fa-stack-2x text-primary"></i>
                  <i className="fas fa-globe fa-stack-1x fa-inverse"></i>
                </span>
                <h4 className="service-heading">Cobertura</h4>
                <p className="text-muted">
                  Contamos con cobertura en toda la Rep&uacute;blica Mexicana a
                  trav&eacute;s de nuestros 10 centros de distribuci&oacute;n y
                  m&aacute;s de 80 sucursales.
                </p>
              </div>
              <div className="col-md-4">
                <span className="fa-stack fa-4x">
                  <i className="fas fa-circle fa-stack-2x text-primary"></i>
                  <i className="fas fa-car fa-stack-1x fa-inverse"></i>
                </span>
                <h4 className="service-heading">Desarrollo</h4>
                <p className="text-muted">
                  Somos innovadores y vanguardistas, contamos con procesos que
                  nos permiten desarrollar aplicaciones de autos de reciente
                  modelo en tiempo r&eacute;cord.
                </p>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </Fragment>
    );
  }
}
export default Services;
