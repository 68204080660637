import axios from "axios";
import global from "./global";

export function getSucursales() {
  const apiCall = global.apiRESTUrl + "/obtieneSucursales";
  return axios
    .get(apiCall)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

/*export function getUbicacionesGrupo() {
  const apiCall = global.apiRESTUrl + "/obtieneUbicacionesGrupo";
  return axios
    .get(apiCall)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}*/

export function getUbicacionesGrupoNOCEDIS() {
  const apiCall = global.apiRESTUrl + "/obtieneUbicacionesGrupoNOCEDIS";
  return axios
    .get(apiCall)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export function getUbicacionSucursales(ordenGrupo) {
  const apiCall = global.apiRESTUrl + "/obtieneUbicacionSucursales/" + ordenGrupo;
  
  return axios
    .get(apiCall)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export function getConfig(config) {
  const apiCall = global.apiRESTUrl + "/obtieneConfiguraciones/" + config;
  return axios
    .get(apiCall)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export async function sendMail(
  pMailSender,
  pMailSenderPwd,
  pMailReceiver,
  pNombre,
  pEmail,
  pTelefono,
  pComentarios,
  pEstado,
  pCiudad
) {
  const apiCall = global.apiRESTUrl + "/enviarEMail";
  const response = await axios
    .post(apiCall, {
      mailSender: pMailSender,
      mailSenderPwd: pMailSenderPwd,
      mailReceiver: pMailReceiver,
      nombre: pNombre,
      email: pEmail,
      telefono: pTelefono,
      comentarios: pComentarios,
      estado: pEstado,
      ciudad: pCiudad,
    })
    .catch((error) => {
      return error;
    });
  try {
    if (response.data.status === "success") {
      return 0;
    } else {
      return 1;
    }
  } catch (error) {
    console.log(error);
    return 1;
  }
}

export function getDepartamentos() {
  const apiCall = global.apiRESTUrl + "/obtieneDepartamentos";
  return axios
    .get(apiCall)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export function getEstados() {
  const apiCall = global.apiRESTUrl + "/obtieneEstados";
  return axios
    .get(apiCall)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export function getFamiliasImg() {
  const apiCall = global.apiRESTUrl + "/obtieneFamiliasImg";
  return axios
    .get(apiCall)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export function getFamilias(idTipoVehiculo, modelo) {
  const apiCall =
    global.apiRESTUrl + "/obtieneFamilias/" + idTipoVehiculo + "/" + modelo;

  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
}

export function obtieneFamiliaProductos(idFamilia) {
  const apiCall = global.apiRESTUrl + "/obtieneFamiliaProductos/" + idFamilia;
  return axios
    .get(apiCall)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export async function comprobarCaptcha(captchA) {
  const apiCall = global.apiRESTUrl + "/compruebaCaptcha";
  return await axios.post(apiCall, { captcha: captchA }).catch((error) => {
    return error;
  });
}

export function getMarcas() {
  const apiCall = global.apiRESTUrl + "/obtieneMarcas";

  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
}

export function getModelos(idTipoVehiculo) {
  const apiCall = global.apiRESTUrl + "/obtieneModelos/" + idTipoVehiculo;

  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
}

export function getProductos(idTipoVehiculo, idFamilia, modelo) {
  const apiCall =
    global.apiRESTUrl +
    "/obtieneProductos/" +
    idTipoVehiculo +
    "/" +
    idFamilia +
    "/" +
    modelo;

  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
}

export function getVehiculos(idMarca) {
  const apiCall = global.apiRESTUrl + "/obtieneVehiculos/" + idMarca;

  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
}

export function getAllVehiculos() {
  const apiCall = global.apiRESTUrl + "/obtieneTodosVehiculos";

  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
}
export function getAllVehiculosServicioPesado() {
  const apiCall = global.apiRESTUrl + "/obtieneTodosVehiculosServicioPesado";

  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
}

export function getProductosByCode(codigo, equivalencias) {
  const apiCall =
    global.apiRESTUrl +
    "/obtieneProductosByCode/" +
    codigo +
    "/" +
    equivalencias;

  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
}

export function getProductosByCodeDetalle(codigo) {
  const apiCall =
    global.apiRESTUrl +
    "/obtieneProductosByCodeDetalle/" +
    codigo

  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
}

export function getProductosByDescripcion(desc) {
  const apiCall = global.apiRESTUrl + "/obtieneProductosByDescripcion/" + desc;

  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
}

export function numberFormat(value) {
  return new Intl.NumberFormat("en-US", {
    style: "decimal", //currency
    currency: "MXN",
    currencyDisplay: "symbol",
    minimumFractionDigits: 0,
  }).format(value);
}

export function addDefaultSrc(ev) {
  ev.target.src = global.foto_nd;
}
