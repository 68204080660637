import React, { Fragment } from "react";
import { isMobileOnly } from "react-device-detect";

import SearchByVehicle from "./SearchByVehicle";
import SearchByMaker from "./SearchByMaker";
import SearchByCode from "./SearchByCode";
import SearchByProduct from "./SearchByProduct";
import SearchByHeavyService from "./SearchByHeavyService";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import Global from "../global";

const Catalogue = () => {
  return (
    <Fragment>
      
    
      <Menu />

    
      <section className="page-section" id="catalogo">
        <div className="container">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading text-uppercase">CATÁLOGO</h2>
            <h3 className="section-subheading  mb-5 text-muted">
               {Global.leyendaCatalogo} 
            </h3>
          </div>
          <div className="row ">
            <div className="col-md-12">
              <nav>
                <div
                  className="nav nav-tabs bg-light"
                  id="nav-tab"
                  role="tablist"
                >
                 <a
                    className="nav-item nav-link active"
                    id="nav-2-tab"
                    data-toggle="tab"
                    href="#nav-marca"
                    role="tab"
                    aria-controls="nav-marca"
                    aria-selected="false"
                  >
                    Marca
                  </a>
                  <a
                    className="nav-item nav-link "
                    id="nav-4-tab"
                    data-toggle="tab"
                    href="#nav-descripcion"
                    role="tab"
                    aria-controls="nav-descripcion"
                    aria-selected="false"
                  >
                    {isMobileOnly === true ? "Desc." : "Descripción"}
                  </a>
                  <a
                    className="nav-item nav-link "
                    id="nav-1-tab"
                    data-toggle="tab"
                    href="#nav-vehiculo"
                    role="tab"
                    aria-controls="nav-vehiculo"
                    aria-selected="true"
                  >
                    Vehículo
                  </a>
                 
                  <a
                    className="nav-item nav-link"
                    id="nav-3-tab"
                    data-toggle="tab"
                    href="#nav-codigo"
                    role="tab"
                    aria-controls="nav-3"
                    aria-selected="false"
                  >
                    Código
                  </a>
                
                  <a
                    className="nav-item nav-link"
                    id="nav-5-tab"
                    data-toggle="tab"
                    href="#nav-servPesado"
                    role="tab"
                    aria-controls="nav-servPesado"
                    aria-selected="false"
                  >
                   Servicio Pesado
                  </a>
                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">
              <div
                  className="tab-pane fade show active" 
                  id="nav-marca"
                  role="tabpanel"
                  aria-labelledby="nav-2-tab"
                >
                  <SearchByMaker />
                </div>
              <div
                  className="tab-pane fade "
                  id="nav-descripcion"
                  role="tabpanel"
                  aria-labelledby="nav-4-tab"
                >
                  <SearchByProduct />
                </div>
                <div
                  className="tab-pane fade "
                  id="nav-vehiculo"
                  role="tabpanel"
                  aria-labelledby="nav-1-tab"
                >
                  <SearchByVehicle />
                </div>
              
                <div
                  className="tab-pane fade"
                  id="nav-codigo"
                  role="tabpanel"
                  aria-labelledby="nav-3-tab"
                >
                  <SearchByCode />
                </div>
               
                <div
                  className="tab-pane fade"
                  id="nav-servPesado"
                  role="tabpanel"
                  aria-labelledby="nav-5-tab"
                >
                  <SearchByHeavyService />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
   
    </Fragment>
    
    
  );
  
};

export default Catalogue;
