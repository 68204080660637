import React from "react";
import "primereact/resources/themes/rhea/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import Router from "./router";

function App() {
  return <Router />;
}

export default App;
